<template>
  <div class="input-container">
    <p class="title-label">
      <slot></slot>
    </p>

    <input
        class="input"
        ref="inputRef"
        type="text"
    >
    <p :class="altCss ? 'input-error-alt' : 'input-error'" v-if="error && error.$error">{{ errorText }}</p>
  </div>
</template>

<script>
import {useCurrencyInput} from 'vue-currency-input'

export default {
  name: 'InputCurrency',
  emits: ['update:modelValue'],
  props: {
    altCss: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: Object,
    modelValue: {
      type: [String, Number],
    },
    error: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {inputRef} = useCurrencyInput(props.options)

    return {inputRef}
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    errorText() {
      return this.error && this.error.$errors.length > 0 ? this.error.$errors[0].$message : ""
    },
  },
}
</script>

<style lang="scss" scoped>
.input-container {
  width: 100%;
  padding: 8px 0px;

}

.title-label {
  font-size: 16px;
  font-weight: 700;
  line-height: 33px;
  color: $blue-darker;
  margin-bottom: 5px;

  span {
    color: $red;
    font-size: 20px;
  }

  @media screen and (max-width: 768px) {
    font-size: 15px;

    span {
      color: $red;
      font-size: 17px;
    }
  }
}

.title-label:empty {
  display: none;
  margin: 0;
}

input:focus[placeholder], [placeholder], *[placeholder] {
  color: #0C2E3C !important;
  background-color: white;
  border-color: #0C2E3C;
}

.input {
  transition: all .33s ease;
  height: 55px;
  width: 100%;
  padding: 18px 15px;
  font-size: 16px;
  font-weight: 500;
  background: #fff;
  border: 1px solid $grey;
  border-radius: 3px;
  outline: none;


  @media screen and (max-width: 768px) {
    height: 50px;
    padding: 16px 13px;
    font-size: 13px;
  }
}

.input-disabled {
  cursor: not-allowed;
  background: #ddd;
}

.input-error {
  font-size: 13px;
  color: $red;
}

.input-error-alt {
  font-size: 16px;
  color: $grey;
}
</style>